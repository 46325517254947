import {useState, useLayoutEffect} from 'react'
import { get } from 'lodash'

const useBanner = bannerRef => {
  const [bannerHeight, setBannerHeight] = useState(56)
  const [showBanner, setShowBanner] = useState(true)

  const resizeListener = () => {
    let banner = window.sessionStorage.getItem('showBanner') === 'false' ? false : true
    setShowBanner(banner)
    setBannerHeight(banner ? get(bannerRef, 'current.clientHeight', 56) : 0)
  }

  useLayoutEffect(() => {
    resizeListener()
    window.addEventListener('resize', resizeListener)
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [bannerRef])

  const closeClick = () => {
    setBannerHeight(0)
    setShowBanner(false)
    window.sessionStorage.setItem('showBanner', 'false')
  }

  return [bannerHeight, showBanner, closeClick]
}

export default useBanner
