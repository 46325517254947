import styled from 'styled-components'
import { Link } from 'gatsby'
import Button from '@eig-builder/core-ui/Button'
import ArrowForward from '@material-ui/icons/ArrowForwardIos'

const Banner = styled.div`
  background-color: ${props => !props.isWhiteBanner ? '#0076DF' : 'white'};
  width: 100%;
  color: ${props => props.isWhiteBanner ? '#0076DF' : 'white'};
  font-size: 1em;
`
Banner.buttonContainer = styled.div`
  width: 100%;
  @media(min-width: 576px){
    width: auto;
  }
`
Banner.Link = styled(Link)`
  flex-grow: 1;
  display: inline-block;
  color: ${props => props.isWhiteBanner ? '#0076DF' : 'white'};
  font-weight: bold;
`
Banner.arrow = styled(ArrowForward)`
  display: inline-block;
  transform: translateY(2px);
  margin-left: 5px;
`
Banner.Close = styled(Button)`
  span {
    font-size: 24pt;
    font-weight: 100;
    line-height: .8em;
    transform: translateY(-3px);
  }
`

export default Banner
