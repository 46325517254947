const ADDRESS = {
  STREET: '5335 Gate Pkwy, Second Floor',
  ETC: 'Jacksonville, FL 32256'
}

const HOME = {
  label: 'Home',
  path: '/'
}

const FEATURES = {
  label: 'Features',
  path: '/features'
}

const PRICING = {
  label: 'Pricing',
  path: '/pricing'
}

const SUPPORT = {
  label: 'Support',
  path: process.env.GATSBY_KB_URL,
  newTab: true
}

const TERMS_OF_USE = {
  label: 'Terms of Use',
  path: '/terms'
}

const PRIVACY_NOTICE = {
  label: 'Privacy',
  path: 'https://newfold.com/privacy-center',
  newTab: true
}

const ABUSE = {
  label: 'Abuse',
  path: '/abuse'
}

const LOG_IN = {
  label: 'Log In',
  path: process.env.GATSBY_LOGIN_URL
}

const DO_NOT_SELL = {
  label: 'Do Not Sell My Personal Information',
  path: 'https://www.newfold.com/privacy-center/addendum-for-california-users',
  newTab: true
}

const COOKIE_SETTINGS = {
  label: 'Cookie Settings',
  path: '#'
}

const ALL_LINKS = [
  PRIVACY_NOTICE,
  TERMS_OF_USE,
  ABUSE,
  DO_NOT_SELL,
  COOKIE_SETTINGS
]

export { ADDRESS, ALL_LINKS }
