/* eslint-disable react/prop-types */
/**
 *
 */

import React from 'react'
import callToActionImage from '../../images/logo-webcom.webp'
import Head from '../header/header.styles'

const PopPup = ({ callToAction, setCallToAction, setCallToActionB, head }) => {
  return (
    <>
      {head ? (
        <Head.callToActionContainerHead isOpen={callToAction}>
          <Head.callToActionBar onClick={() => setCallToAction(false)}>
            <span>X</span>
          </Head.callToActionBar>
          <Head.callToActionContent>
            <Head.callToActionImage src={callToActionImage} alt='call to action' />
            <Head.callToActionDescription>Our partner brand Web.com delivers everything you need to grow your business. Web.com One Stop. All Done.</Head.callToActionDescription>
            <Head.callToActionRedirectText>You will now be redirected to Web.com</Head.callToActionRedirectText>
          </Head.callToActionContent>
        </Head.callToActionContainerHead>
  ) : (
      <Head.callToActionContainer isOpen={callToAction}>
          <Head.callToActionBar onClick={() => setCallToAction(false)}>
            <span>X</span>
          </Head.callToActionBar>
          <Head.callToActionContent>
            <Head.callToActionImage src={callToActionImage} alt='call to action' />
            <Head.callToActionDescription>Our partner brand Web.com delivers everything you need to grow your business. Web.com One Stop. All Done.</Head.callToActionDescription>
            <Head.callToActionRedirectText>You will now be redirected to Web.com</Head.callToActionRedirectText>
          </Head.callToActionContent>
        </Head.callToActionContainer>
    )
    }
    </>
  )
}

export default PopPup
