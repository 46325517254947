import { useEffect, useState } from 'react'
import useMobileDetection from '../../helpers/hooks/useMobileDetection'

const useResponsiveHeader = menuContainerRef => {
  const [isMobile, showChildren] = useMobileDetection(800)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const checkClick = e => {
      if (open) {
        if (!menuContainerRef.current.contains(e.target)) {
          setOpen(false)
        }
      }
    }
    if (window && isMobile) {
      window.addEventListener('click', checkClick)
    }
    return () => {
      window.removeEventListener('click', checkClick)
    }
  }, [open, isMobile, menuContainerRef])

  return [isMobile, open, setOpen, showChildren]
}

export default useResponsiveHeader
