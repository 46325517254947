import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
// import { getCookie } from '../../helpers/cookie-helper'

const Affiliate = styled.div`
  position: fixed;
  bottom: 0;
  background-color: rgba(77, 77, 79, 0.8);
  align-items: top;
  color: rgba(255, 255, 255, 0.8);
  display: ${props => (props.open ? 'flex' : 'none')};
  z-index: 100;
  padding: 15px;
  width: 100%;
  justify-content: center;
`

Affiliate.Button = styled.span`
  position: absolute;
  top: 13px;
  right: 15px;
  color: rgba(255, 255, 255, 0.8);
  &:hover {
    cursor: pointer;
  }
`

Affiliate.text = styled.span`
  padding-right: 20px;
  display: inline-block;
  font-size: 0.8em;
`

const AffiliateComponent = () => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!window || typeof window === 'undefined') return
    const dismissed = JSON.parse(
      window.localStorage.getItem('affiliate-dismissed')
    )
    if (dismissed) {
      setOpen(false)
    } else {
      // const affiliateCookie = getCookie('ir_cid')
      // if (
      //   affiliateCookie &&
      //   typeof affiliateCookie !== 'undefined' &&
      //   affiliateCookie !== ''
      // ) {
      //   console.log('open')
      setOpen(true)
      // }
    }
  }, [])

  const disable = () => {
    if (!window || typeof window === 'undefined') return
    window.localStorage.setItem('affiliate-dismissed', 'true')
    setOpen(false)
  }

  return (
    <Affiliate open={open}>
      <Affiliate.text>
        Thanks for visiting! We hope you enjoy your Sitebuilder experience. Just
        to let you know you visited one of our paid endorsements before arriving
        at our site.
      </Affiliate.text>
      <Affiliate.Button variant='text' onClick={disable}>
      ×
      </Affiliate.Button>
    </Affiliate>
  )
}

export default AffiliateComponent
