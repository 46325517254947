import React from 'react'

import Foot from './footer.styles'
import Box from '../../elements/Box'
import Logo from '../../images/sitebuildercom_white.svg'

import { ADDRESS, ALL_LINKS } from './footer.constants'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

const Footer = () => {
  return (
    <Foot justifyContent='space-between' alignItems='center' py={4} px={4}>
      <Foot.section pb={3}>
        <Box flexDirection='column'>
          <Foot.logo to='/' width='233' src={Logo} alt='logo' />
          <Foot.Text variant='body1'>{ADDRESS.STREET}</Foot.Text>
          <Foot.Text variant='body1'>{ADDRESS.ETC}</Foot.Text>
        </Box>
      </Foot.section>
      <Foot.section py={3} alignSelf='flex-start'>
        <Foot.grid
          justifyItems='flex-start'
          gridTemplateRows='auto'
          gridRowGap={4}
        >
          {ALL_LINKS.map(({ label, path, newTab = false }) => (
            <Foot.link
              key={label}
              target={newTab ? '_blank' : ''}
              rel={newTab ? 'noopener' : ''}
              href={path}
              p={2}
              data-element-location={DataElementLocations.FOOTER}
              data-element-label={`footer.${label.split(' ').join('-')}`}
              className={ label == 'Cookie Settings' ? 'ot-sdk-show-settings':''}
              data-element-id={`sitebuilder-footer-footer.${label
                .split(' ')
                .join('-')}`}
            >
              <Foot.Text variant='body2'>{label}</Foot.Text>
            </Foot.link>
          ))}
        </Foot.grid>
      </Foot.section>
    </Foot>
  )
}

export default Footer
