import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import IconButton from '@eig-builder/core-ui/IconButton'
import Menu from '@material-ui/icons/Menu'

const Head = styled.div(
  ({ scrollTheme }) => ({ ...scrollTheme }),
  ({ stickyHeight})  => `
    position: sticky;
    top: 0px;
    margin-bottom: -84px
    width: 100%;
    z-index: 20;
    transition: all .5s;
  `
)
Head.container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  width: 100%;
  height: 84px;
  box-sizing: border-box;
  align-items: center;
`

Head.section = styled.div`
  position: relative;
  flex-grow: 1;
`
Head.LogoImage = styled.img`
  heght: 60px;
  width: 207px;
  @media (max-width: 920px) {
    height: 44px;
    width: 152px;
  }
`
Head.Logo = styled(Link).attrs(({to, ...props}) => ({
  children: <Head.LogoImage {...props} />,
}))`
  transform: translateY(2px);
  display: inline-block;
`
Head.screenReaderText = styled.div.attrs(() => ({ className: 'screen-reader-text', ariaLabel: 'Sitebuilder Assistance Info' }))`
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal;
`

Head.navContainer = styled.div`
  margin: 0 auto;
  text-align: center;
`
Head.loginContainer = styled.div`
  text-align: right;
  margin: 0 auto;
`
const buttonStyle = css`
  line-height: 2.2em;
  font-size: 13pt;
  padding: 0px 15px;
  margin: 5px 10px;
  display: inline-block;

  @media (max-width: 920px) {
    font-size: 11pt;
    padding: 0px 8px;
  }
`

Head.ExternalButton = styled.a(
({ scrollTheme }) => ({ ...scrollTheme }),
`
${buttonStyle}
box-shadow: none;
border: none;
background-color: transparent;
cursor: pointer;
`
)

Head.NavButton = styled(Link)(
  ({ scrollTheme }) => ({ ...scrollTheme }),
 `
 ${buttonStyle}
 box-shadow: none;
 border: none;
background-color: transparent;
`
)

Head.SignUp = styled.a(
  ({ scrollTheme }) => ({ ...scrollTheme }),`
  ${buttonStyle}
  border: solid 1px;
  border-radius: 5px;
  box-shadow: none;
  cursor: pointer;
`
)

Head.hamburgerContainer = styled.div`
  text-align: right;
  margin: 0 auto;
  display: none;
  @media (max-width: 1234px) {
    display: inherit;
  }
`

Head.Hamburger = styled(IconButton).attrs(({ scrolltheme }) => ({
  children: <Menu style={{ fill: scrolltheme.color }} />,
  size: 'large'
}))``

Head.callToActionContainerHead = styled.div`
  width:100%;
  max-width: 400px;
  height: 210px;
  background: white;
  padding: 0px;
  color: #000;
  z-index: 10;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  @media (max-width:800px){
    max-width:350px;
    z-index:101;
  }
`

Head.PopupContainer = styled.div`
  position:absolute;
  left:50%;
  transform:translateX(-50%);
`

Head.callToActionContainer = styled.div`
  width:100%;
  max-width: 400px;
  height: 210px;
  background: white;
  padding: 0px;
  color: #000;
  position: absolute;
  z-index: 10;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};

  @media (max-width:1023px){
    z-index:101;
  }
`

Head.callToActionBar = styled.div`
  width: 100%;
  height: 40px;
  background: #f5f5f5;
  padding: 0px;
  border-bottom: 1px solid #d3d3d3;
  text-align: end;
  padding-right: 15px;
  font-size: 17px;
  padding-top: 8px;
  font-weight: bold;
  cursor: pointer;
`

Head.callToActionContent = styled.div`
  width: 100%;
  padding: 10px 20px;
`

Head.callToActionDescription = styled.span`
  font-size: 15px;
  font-weight: bold;
  text-aling: start;
  display: block;
  margin-top: 10px;
  margin-bottom: 5px
`

Head.callToActionRedirectText = styled.span`
  font-size: 12px;
  display: block;
`

Head.callToActionImage = styled.img`
  width: 100%;
  max-width: 200px;
`

export default Head
