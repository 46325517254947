import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
// noinspection ES6CheckImport
import { display, layout, flexbox, background, color, space } from 'styled-system'

import Typography from '@eig-builder/core-ui/Typography'

const Foot = styled.section(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.darkGray.main,
    color: theme.palette.lightGray.main
  }),
  display,
  background,
  color,
  layout,
  flexbox,
  space
)

Foot.section = styled.div(
  {
    display: 'flex',
    flex: 1,
    minWidth: '250px'
  },
  display,
  layout,
  flexbox,
  space
)
Foot.logoImage = styled.img``
Foot.logo = styled(Link).attrs(({to, ...props}) => ({
  children: <Foot.logoImage {...props} />
}))``

Foot.link = styled.a(
  ({ theme }) => ({
    textDecoration: 'unset',
    color: theme.palette.lightGray.main
  }),
  space
)

Foot.Text = styled(Typography)``

Foot.grid = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 1198px) {
    display: grid;
    flex: 1;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
`

export default Foot
