import { useState, useEffect } from 'react'
import useWindowDimensions from './useWindowDimensions'

const useMobileDetection = (threshold = 620) => {
  const [isMobile, setIsMobile] = useState(false)
  const [showChildren, setShowChildren] = useState(false)
  const { width } = useWindowDimensions()

  useEffect(() => {
    if (width <= threshold) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
    setShowChildren(true)
  }, [width, threshold])

  return [isMobile, showChildren]
}

export default useMobileDetection
