import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

import Head from './header.styles'
import SideNav from './sidenav.styles'
import Banner from '../banner'
import useResponsiveHeader from '../../helpers/hooks/useResponsiveHeader'
import useScrollTheme from '../../helpers/hooks/useScrollTheme'
import useBanner from '../../helpers/hooks/useBanner'
import callToActionImage from '../../images/logo-webcom.webp'
import SitebuilderLogoWhite from '../../images/sitebuildercom_white.svg'
import SitebuilderLogo from '../../images/sitebuildercom.webp'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'
import PopPup from '../pop-pup'

const Header = ({ isWhiteHeader, isWhiteBanner }) => {
  const [callToAction, setCallToAction] = useState(false)
  const menuContainerRef = useRef()
  const bannerRef = useRef()
  const [bannerHeight, showBanner, closeClick] = useBanner(bannerRef)
  const [isMobile, open, setOpen, showChildren] = useResponsiveHeader(
    menuContainerRef
  )
  const [scrollTheme, scroll] = useScrollTheme(isWhiteHeader)

  const redirectWebdotcom = (websiteUrl) => {
    setTimeout(() => {
      document.location.href = websiteUrl
      setCallToAction(false)
    }, 5000)
  }

  const redirectWebDotComWithCallAction = (websiteUrl) => {
    setCallToAction(true)
    redirectWebdotcom(websiteUrl)
  }
  
  return (
    <>
      <Head scrollTheme={scrollTheme} stickyHeight={bannerHeight}>
        <Head.container>
        <Head.section>
          <Head.Logo
            to='/'
            height='60px'
            width='207px'
            src={
              isWhiteHeader || scroll ? SitebuilderLogoWhite: SitebuilderLogo
            }
            alt='sitebuilder logo'
          />
          <Head.screenReaderText>
            If you are using assistive technology and are unable to read any
            part of the SiteBuilder.com website, or otherwise have
            difficulties using the SiteBuilder.com website, please call
            844-211-7881 and our customer service team will assist you.
          </Head.screenReaderText>
        </Head.section>
        {showChildren &&
          (isMobile ? (
            <Head.section>
              <Head.hamburgerContainer>
                <Head.Hamburger
                  dataElementLocation={DataElementLocations.HEADER}
                  dataElementLabel='header.exit'
                  dataElementId='sitebuilder-mobile-exit-button'
                  onClick={() => setOpen(previous => !previous)}
                  scrolltheme={scrollTheme}
                />
              </Head.hamburgerContainer>
            </Head.section>
          ) : (
            <>
              <Head.section>
                <Head.navContainer>
                <Head.ExternalButton
                    data-element-location={DataElementLocations.HEADER}
                    data-element-label='websites'
                    data-element-id='sitebuilder-desktop-websites'
                    scrollTheme={scrollTheme}
                  >
                    <span onClick={() => {
                      setCallToAction(true)
                      redirectWebdotcom('https://www.web.com/websites')
                      }}>
                        Websites
                    </span>
                  </Head.ExternalButton>
                  <Head.ExternalButton
                    data-element-location={DataElementLocations.HEADER}
                    data-element-label='marketing'
                    data-element-id='sitebuilder-desktop-marketing'
                    scrollTheme={scrollTheme}
                  >
                    <span onClick={() => {
                      setCallToAction(true)
                      redirectWebdotcom('https://www.web.com/online-marketing')
                    }}>
                      Marketing
                    </span>
                  </Head.ExternalButton>
                  <Head.ExternalButton
                    data-element-location={DataElementLocations.HEADER}
                    data-element-label='domains'
                    data-element-id='sitebuilder-desktop-domains'
                    scrollTheme={scrollTheme}
                  >
                    <span onClick={() => {
                      setCallToAction(true)
                      redirectWebdotcom('https://www.web.com/domains')
                    }}>
                      Domains
                    </span>
                  </Head.ExternalButton>
                  <Head.ExternalButton
                    data-element-location={DataElementLocations.HEADER}
                    data-element-label='hosting'
                    data-element-id='sitebuilder-desktop-hosting'
                    scrollTheme={scrollTheme}
                  >
                    <span onClick={() => {
                        setCallToAction(true)
                        redirectWebdotcom('https://www.web.com/websites/hosting')
                    }}>
                      Hosting
                    </span>
                  </Head.ExternalButton>
                  <Head.ExternalButton
                    data-element-location={DataElementLocations.HEADER}
                    data-element-label='security'
                    data-element-id='sitebuilder-desktop-security'
                    scrollTheme={scrollTheme}
                  >
                    <span onClick={() => {
                      setCallToAction(true)
                      redirectWebdotcom('https://www.web.com/website-security')
                    }}>
                      Security
                    </span>
                  </Head.ExternalButton>
                  <Head.ExternalButton
                    data-element-location={DataElementLocations.HEADER}
                    data-element-label='email-services'
                    data-element-id='sitebuilder-desktop-email-services'
                    scrollTheme={scrollTheme}
                  >
                    <span onClick={() => {
                      setCallToAction(true)
                      redirectWebdotcom('https://www.web.com/email-service')
                    }}>
                      Email
                    </span>
                  </Head.ExternalButton>
                  <Head.ExternalButton
                    data-element-location={DataElementLocations.HEADER}
                    data-element-label='expert-services'
                    data-element-id='sitebuilder-desktop-expert-services'
                    scrollTheme={scrollTheme}
                  >
                    <span onClick={() => {
                      setCallToAction(true)
                      redirectWebdotcom('https://www.web.com/websites/pro-website-services')
                    }}>
                      Expert Services
                    </span>
                  </Head.ExternalButton>
                </Head.navContainer>
              </Head.section>
              <Head.section>
                <Head.loginContainer>
                  <Head.ExternalButton
                    data-element-location={DataElementLocations.HEADER}
                    data-element-label='header.login'
                    data-element-id='sitebuilder-desktop-login-button'
                    scrollTheme={scrollTheme}
                    href={process.env.GATSBY_LOGIN_URL}
                  >
                      Login
                  </Head.ExternalButton>
                  <Head.SignUp
                    data-element-location={DataElementLocations.HEADER}
                    data-element-label='header.signup'
                    data-element-id='sitebuilder-desktop-signup-button'
                    scrollTheme={scrollTheme}
                  >
                  <span onClick={() => {
                      setCallToAction(true)
                      redirectWebdotcom('https://www.web.com/websites/online-store-builder#packages')
                    }}>
                      Sign Up
                    </span>
                  </Head.SignUp>
                </Head.loginContainer>
              </Head.section>
            </>
          ))}
        </Head.container>
        {!isMobile && 
          (<Head.PopupContainer>
            <PopPup callToAction={callToAction} setCallToAction={setCallToAction} head />
          </Head.PopupContainer>)}
      </Head>
      {isMobile && 
        (<SideNav.PopupContainer>
          <PopPup callToAction={callToAction} setCallToAction={setCallToAction} head />
        </SideNav.PopupContainer>)}
      {isMobile && (
        <SideNav open={open} ref={menuContainerRef}>
          <SideNav.Exit
            dataElementLocation={DataElementLocations.HEADER}
            dataElementLabel='header.exit'
            dataElementId='sitebuilder-mobile-exit-button'
            onClick={() => setOpen(false)}
          >
            &times;
          </SideNav.Exit>
          <SideNav.container>
            <SideNav.NavButton
                data-element-location={DataElementLocations.HEADER}
                data-element-label='header.websites'
                data-element-id='sitebuilder-mobile-websites-button'
            >
              <span onClick={() => {
                      redirectWebDotComWithCallAction('https://www.web.com/websites')
                      }}>
                  Websites
              </span>
            </SideNav.NavButton>
            <SideNav.NavButton
                data-element-location={DataElementLocations.HEADER}
                data-element-label='header.marketing'
                data-element-id='sitebuilder-mobile-marketing-button'
            >
              <span onClick={() => {
                  redirectWebDotComWithCallAction('https://www.web.com/online-marketing')
              }}>
                  Marketing
              </span>
            </SideNav.NavButton>
            <SideNav.NavButton
                data-element-location={DataElementLocations.HEADER}
                data-element-label='header.domains'
                data-element-id='sitebuilder-mobile-domains-button'
            >
              <span onClick={() => {
                  redirectWebDotComWithCallAction('https://www.web.com/domains')
              }}>
                  Domains
              </span>
            </SideNav.NavButton>
            <SideNav.ExternalButton
                data-element-location={DataElementLocations.HEADER}
                data-element-label='header.hosting'
                data-element-id='sitebuilder-mobile-hosting-button'
            >
              <span onClick={() => {
                  redirectWebDotComWithCallAction('https://www.web.com/websites/hosting')
              }}>
                  Hosting
              </span>
            </SideNav.ExternalButton>
            <SideNav.ExternalButton
                data-element-location={DataElementLocations.HEADER}
                data-element-label='header.security'
                data-element-id='sitebuilder-mobile-security-button'
            >
              <span onClick={() => {
                  redirectWebDotComWithCallAction('https://www.web.com/website-security')
              }}>
                  Security
              </span>
            </SideNav.ExternalButton>
            <SideNav.ExternalButton
                data-element-location={DataElementLocations.HEADER}
                data-element-label='header.email'
                data-element-id='sitebuilder-mobile-email-button'
            >
              <span onClick={() => {
                  redirectWebDotComWithCallAction('https://www.web.com/email-service')
              }}>
                  Email
              </span>
            </SideNav.ExternalButton>
            <SideNav.ExternalButton
                data-element-location={DataElementLocations.HEADER}
                data-element-label='header.expert-services'
                data-element-id='sitebuilder-mobile-expert-services-button'
            >
              <span onClick={() => {
                  redirectWebDotComWithCallAction('https://www.web.com/websites/pro-website-services')
              }}>
                  Expert Services
              </span>
            </SideNav.ExternalButton>
            <SideNav.ExternalButton
                data-element-location={DataElementLocations.HEADER}
                data-element-label='header.login'
                data-element-id='sitebuilder-mobile-login-button'
                scrollTheme={scrollTheme}
                href={process.env.GATSBY_LOGIN_URL}
            >
                Log In
            </SideNav.ExternalButton>
            <SideNav.SignUp
                dataElementLocation={DataElementLocations.HEADER}
                dataElementLabel='header.signup'
                dataElementId='sitebuilder-mobile-signup-button'
            >
              <span onClick={() => {
                                redirectWebDotComWithCallAction('https://www.web.com/websites/online-store-builder#packages')
                              }}>
                  Sign Up
              </span>
            </SideNav.SignUp>
          </SideNav.container>
        </SideNav>
      )}
    </>
  )
}
Header.propTypes = {
  isWhiteHeader: PropTypes.bool,
  isWhiteBanner: PropTypes.bool
}

export default Header
