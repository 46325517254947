/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from './../header'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import './style/layout.scss'

import { setBuilderBar } from '@eig-builder/core-utils/helpers/builder-bar-helper'
import withLocalization from '@eig-builder/module-localization/withLocalization'

import GlobalStyle from './GlobalStyles'
import Footer from '../footer'
import Affiliate from '../affiliate'

setBuilderBar(process.env.GATSBY_FOS_URL + '/')

const Layout = ({
  children,
  isWhiteHeader,
  isWhiteBanner,
  paddingTop,
  grayBackground,
  classes
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <GlobalStyle />
        <Header isWhiteHeader={isWhiteHeader} isWhiteBanner={isWhiteBanner} />
        <Affiliate />
        <main
          className={classNames(classes.root, {
            [classes.paddingTop]: paddingTop,
            [classes.grayBackground]: grayBackground
          })}
        >
          {children}
        </main>
        <Footer />
        <div id='teconsent-container' />
      </>
    )}
  />
)

const styles = {
  root: {
    minHeight: 'calc(100vh - 159px)'
  },
  paddingTop: {
    paddingTop: '110px'
  },
  grayBackground: {
    backgroundColor: '#f8f8f8'
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isWhiteHeader: PropTypes.bool,
  isWhiteBanner: PropTypes.bool,
  paddingTop: PropTypes.bool,
  grayBackground: PropTypes.bool,
  classes: PropTypes.object.isRequired
}

// export default Layout
export default withStyles(styles)(withLocalization(Layout))
