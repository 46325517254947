import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import Button from '@eig-builder/core-ui/Button'

const SideNav = styled.div`
  top: 0;
  z-index: 100;
  position: fixed;
  height: 100vh;
  width: 60%;
  min-width: 240px;
  background-color: #333;
  right: ${props => (props.open ? '0%;' : '-100%;')}
  transition: right .3s linear;
`
SideNav.container = styled.div`
  margin-top: 60px;
  padding: 0 40px;
`
const buttonStyles = css`
  padding: 0 20px;
  line-height: 3em;
  color: white;
  display: block;
  font-family: Nunito Sans, sans-serif;
  font-weight: bold;
`
SideNav.ExternalButton = styled.a`
  ${buttonStyles}
`
SideNav.Button = styled(Link)`
  ${buttonStyles}
`

SideNav.ExitButton = styled(Button).attrs(() => ({
  color: 'transparent',
  variant: 'text',
  size: 'medium'
}))`
  font-size: 20pt !important;
`

SideNav.SignUpButton = styled(Button)``

SideNav.NavButton = styled.div.attrs(({ children, ...restProps }) => ({
  children: <SideNav.Button {...restProps}>{children}</SideNav.Button>
}))`
  display: block;
  text-align: left;
`
SideNav.SignUp = styled.div.attrs(
  ({children, ...restProps}) => ({
    children: (
      <SideNav.SignUpButton
        {...restProps}
      >
        {children}
      </SideNav.SignUpButton>
    )
  })
)`
  display: block;
  text-align: left;
`

SideNav.PopupContainer = styled.div`
  position:absolute;
  max-width:400px;
  width:100%;
  top:80px;
  z-index:101;
  left:50%;
  transform:translateX(-50%);
`

SideNav.Exit = styled.div.attrs(
  ({
    onClick,
    children,
    dataElementLocation,
    dataElementId,
    dataElementLabel
  }) => ({
    children: (
      <SideNav.ExitButton
        onClick={onClick}
        dataElementLabel={dataElementLabel}
        dataElementLocation={dataElementLocation}
        dataElementId={dataElementId}
      >
        {children}
      </SideNav.ExitButton>
    )
  })
)`
  position: absolute;
  top: 5px;
  right: 15px;
  opacity: 0.5;
`

export default SideNav
