import { useEffect, useState } from 'react'
import useScroll from './useScroll'

const defaultScrollTheme = {
  backgroundColor: 'transparent',
  color: 'white',
  boxShadow: 'none'
}

const scrollingTheme = {
  backgroundColor: 'white',
  color: 'black',
  '-webkit-box-shadow': '0px 2px 5px 0px rgba(0,0,0,0.3)',
  '-moz-box-shadow': '0px 2px 5px 0px rgba(0,0,0,0.3)',
  'box-shadow': '0px 2px 5px 0px rgba(0,0,0,0.3)'
}

const whiteHeaderTheme = {
  backgroundColor: 'white',
  color: '#444',
  '-webkit-box-shadow': '0px 2px 5px 0px rgba(0,0,0,0.3)',
  '-moz-box-shadow': '0px 2px 5px 0px rgba(0,0,0,0.3)',
  'box-shadow': '0px 2px 5px 0px rgba(0,0,0,0.3)'
}

const useScrollTheme = isWhiteHeader => {
  const scroll = useScroll(56)
  const [scrollTheme, setScrollTheme] = useState(
    isWhiteHeader ? whiteHeaderTheme : defaultScrollTheme
  )

  useEffect(() => {
    if (isWhiteHeader) return
    if (!scroll) {
      setScrollTheme(defaultScrollTheme)
    } else {
      setScrollTheme(scrollingTheme)
    }
  }, [scroll, isWhiteHeader])

  return [scrollTheme, scroll]
}

export default useScrollTheme
