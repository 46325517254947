/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  children,
  pageType,
  flowType
}) {
  if (!pageType) {
    console.error('pageType is required !')
  }
  if (!flowType) {
    console.error('flowType is required !')
  }

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
            image
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaImage = `${site.siteMetadata.siteUrl}${site.siteMetadata.image}`
  const metaTitle = `${title} | ${site.siteMetadata.title}`

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `revisit-after`,
          content: `5 days`
        },
        {
          name: `rating`,
          content: `General`
        },
        {
          name: `robots`,
          content: `noodp, noydir`
        },
        {
          name: `description`,
          content: metaDescription
        },
        {
          name: `viewport`,
          content: `initial-scale=1.0,width=device-width`
        },
        {
          property: `og:url`,
          content: ``
        },
        {
          property: `og:type`,
          content: `Website`
        },
        {
          property: `og:site_name`,
          content: `Websitebuilder.com`
        },
        {
          property: `og:title`,
          content: metaTitle
        },
        {
          property: `og:image`,
          content: metaImage
        },
        {
          property: `image`,
          content: metaImage
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          charset: `utf-8`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `)
              }
            : []
        )
        .concat(meta)}
    >
      <script type='text/javascript'>
        {`window.dataLayer = window.dataLayer || [];
          dataLayer.push({
            pageType: '${ pageType }',
            flowType: '${ flowType }'
          })`}
      </script>
      {children}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: []
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  pageType: PropTypes.string,
  flowType: PropTypes.string
}

export default SEO
